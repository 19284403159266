export type SportCategory = {
  label: string;
  color: string;
  href: string;
  image?: string;
  sections?: SportCategorySection[];
  excludeFromNav?: boolean;
};

export type SportCategorySection = {
  label: string;
  href: string;
  links?: SportCategoryLink[];
};

export type SportCategoryLink = {
  label: string;
  href: string;
};

const prefix = process.env.NEXT_PUBLIC_IS_STAGING
  ? 'https://mxlocker-staging.b-cdn.net'
  : 'https://cdn.mxlocker.com';

const categories: SportCategory[] = [
  {
    label: 'Dirt Bikes',
    image: `${prefix}/assets/dirt-bikes-shadow_1920x2560.png`,
    color: '#5B4040',
    href: '/shop/dirt-bikes',
    sections: [
      {
        label: 'Accessories',
        href: '/shop/dirt-bike-accessories',
        links: [
          {
            label: 'Fluids & Lubrication',
            href: '/shop/dirt-bike-accessories/fluids-lubrication',
          },
          {
            label: 'Garage Mats',
            href: '/shop/dirt-bike-accessories/garage-mats',
          },
          {
            label: 'Gear Bags',
            href: '/shop/dirt-bike-accessories/gear-bags',
          },
          {
            label: 'Nutrition & Hydration',
            href: '/shop/dirt-bike-accessories/nutrition-hydration',
          },
          {
            label: 'Owner & Service Manuals',
            href: '/shop/dirt-bike-accessories/owner-service-manuals',
          },
          {
            label: 'Ramps & Stands',
            href: '/shop/dirt-bike-accessories/ramps-stands',
          },
          {
            label: 'Tie Downs & Straps',
            href: '/shop/dirt-bike-accessories/tie-downs-straps',
          },
          {
            label: 'Tools & Maintenance',
            href: '/shop/dirt-bike-accessories/tools-maintenance',
          },
          {
            label: 'Watches & Wearables',
            href: '/shop/dirt-bike-accessories/watches-wearables',
          },
        ],
      },
      {
        label: 'Dirt Bike Parts',
        href: '/shop/dirt-bike-parts',
        links: [
          {
            label: 'Bars & Controls',
            href: '/shop/dirt-bike-parts/bars-controls',
          },
          {
            label: 'Body Parts & Accessories',
            href: '/shop/dirt-bike-parts/body-parts-accessories',
          },
          {
            label: 'Brakes',
            href: '/shop/dirt-bike-parts/brakes',
          },
          {
            label: 'Cooling Systems',
            href: '/shop/dirt-bike-parts/cooling-systems',
          },
          {
            label: 'Drive',
            href: '/shop/dirt-bike-parts/drive',
          },
          {
            label: 'Engine',
            href: '/shop/dirt-bike-parts/engine',
          },
          {
            label: 'Exhaust',
            href: '/shop/dirt-bike-parts/exhaust',
          },
          {
            label: 'Fuel System',
            href: '/shop/dirt-bike-parts/fuel-system',
          },
          {
            label: 'Intake',
            href: '/shop/dirt-bike-parts/intake',
          },
          {
            label: 'Lights & Electrical',
            href: '/shop/dirt-bike-parts/lights-electrical',
          },
          {
            label: 'Suspension',
            href: '/shop/dirt-bike-parts/suspension',
          },
          {
            label: 'Wheels',
            href: '/shop/dirt-bike-parts/wheels',
          },
        ],
      },
      {
        label: 'Riding Gear',
        href: '/shop/dirt-bike-riding-gear',
        links: [
          {
            label: 'Boots',
            href: '/shop/riding-gear/dirt-bike-and-motocross-boots',
          },
          {
            label: 'Gear',
            href: '/shop/riding-gear/dirt-bike-and-motocross-gear',
          },
          {
            label: 'Goggles & Accessories',
            href: '/shop/riding-gear/dirt-bike-and-motocross-goggles-accessories',
          },
          {
            label: 'Helmets',
            href: '/shop/riding-gear/dirt-bike-and-motocross-helmets',
          },
          {
            label: 'Protective',
            href: '/shop/riding-gear/dirt-bike-and-motocross-protective',
          },
        ],
      },
      {
        label: 'OEM Parts',
        href: '/shop/oem-dirt-bike-parts',
        links: [
          {
            label: 'Beta',
            href: '/shop/beta-oem-dirt-bike-parts',
          },
          {
            label: 'Cobra',
            href: '/shop/cobra-oem-dirt-bike-parts',
          },
          {
            label: 'Gas Gas',
            href: '/shop/gas-gas-oem-dirt-bike-parts',
          },
          {
            label: 'Honda',
            href: '/shop/honda-oem-dirt-bike-parts',
          },
          {
            label: 'Husqvarna',
            href: '/shop/husqvarna-oem-dirt-bike-parts',
          },
          {
            label: 'KTM',
            href: '/shop/ktm-oem-dirt-bike-parts',
          },
          {
            label: 'Kawasaki',
            href: '/shop/kawasaki-oem-dirt-bike-parts',
          },
          {
            label: 'Sherco',
            href: '/shop/sherco-oem-dirt-bike-parts',
          },
          {
            label: 'Suzuki',
            href: '/shop/suzuki-oem-dirt-bike-parts',
          },
          {
            label: 'Yamaha',
            href: '/shop/yamaha-oem-dirt-bike-parts',
          },
        ],
      },
    ],
  },
  {
    label: 'ATV',
    color: '#585972',
    href: '/shop/atv',
    image: `${prefix}/assets/atv-shadows_1920x2560.png`,
    sections: [
      {
        label: 'Accessories',
        href: '/shop/atv-accessories',
        links: [
          {
            label: 'Fluids & Lubrication',
            href: '/shop/atv-accessories/fluids-lubrication',
          },
          {
            label: 'Garage Mats',
            href: '/shop/atv-accessories/garage-mats',
          },
          {
            label: 'Gear Bags',
            href: '/shop/atv-accessories/gear-bags',
          },
          {
            label: 'Nutrition & Hydration',
            href: '/shop/atv-accessories/nutrition-hydration',
          },
          {
            label: 'Owner & Service Manuals',
            href: '/shop/atv-accessories/owner-service-manuals',
          },
          {
            label: 'Ramps & Stands',
            href: '/shop/atv-accessories/ramps-stands',
          },
          {
            label: 'Tie Downs & Straps',
            href: '/shop/atv-accessories/tie-downs-straps',
          },
          {
            label: 'Tools & Maintenance',
            href: '/shop/atv-accessories/tools-maintenance',
          },
          {
            label: 'Watches & Wearables',
            href: '/shop/atv-accessories/watches-wearables',
          },
        ],
      },
      {
        label: 'ATV Parts',
        href: '/shop/atv-parts',
        links: [
          {
            label: 'Audio & Electronics',
            href: '/shop/atv-parts/audio-electronics',
          },
          {
            label: 'Bars & Controls',
            href: '/shop/atv-parts/bars-controls',
          },
          {
            label: 'Body Parts & Accessories',
            href: '/shop/atv-parts/body-parts-accessories',
          },
          {
            label: 'Brakes',
            href: '/shop/atv-parts/brakes',
          },
          {
            label: 'Cooling Systems',
            href: '/shop/atv-parts/cooling-systems',
          },
          {
            label: 'Drive',
            href: '/shop/atv-parts/drive',
          },
          {
            label: 'Electrical & Lighting',
            href: '/shop/atv-parts/electrical-lighting',
          },
          {
            label: 'Engine',
            href: '/shop/atv-parts/engine',
          },
          {
            label: 'Exhaust',
            href: '/shop/atv-parts/exhaust',
          },
          {
            label: 'Fuel System',
            href: '/shop/atv-parts/fuel-system',
          },
          {
            label: 'Intake',
            href: '/shop/atv-parts/intake',
          },
          {
            label: 'Suspension',
            href: '/shop/atv-parts/suspension',
          },
          {
            label: 'Wheels',
            href: '/shop/atv-parts/wheels',
          },
        ],
      },
      {
        label: 'Riding Gear',
        href: '/shop/atv-riding-gear',
        links: [
          {
            label: 'Boots',
            href: '/shop/riding-gear/atv-boots',
          },
          {
            label: 'Gear',
            href: '/shop/riding-gear/atv-gear',
          },
          {
            label: 'Goggles & Accessories',
            href: '/shop/riding-gear/atv-goggles-accessories',
          },
          {
            label: 'Helmets',
            href: '/shop/riding-gear/atv-helmets',
          },
          {
            label: 'Protective',
            href: '/shop/riding-gear/atv-protective',
          },
        ],
      },
      {
        label: 'OEM Parts',
        href: '/shop/oem-atv-parts',
        links: [
          {
            label: 'Arctic Cat',
            href: '/shop/oem-arctic-cat-atv-parts',
          },
          // {
          //   label: 'Bombardier',
          //   href: '/shop/oem-bombardier-atv-parts',
          // },
          {
            label: 'Can-Am',
            href: '/shop/oem-can-am-atv-parts',
          },
          {
            label: 'Honda',
            href: '/shop/oem-honda-atv-parts',
          },
          {
            label: 'Kawasaki',
            href: '/shop/oem-kawasaki-atv-parts',
          },
          // {
          // label: 'KTM',
          // href: '/shop/oem-ktm-atv-parts',
          // },
          // {
          // label: 'Polaris',
          // href: '/shop/oem-polaris-atv-parts',
          // },
          {
            label: 'Suzuki',
            href: '/shop/oem-suzuki-atv-parts',
          },
          {
            label: 'Yamaha',
            href: '/shop/oem-yamaha-atv-parts',
          },
        ],
      },
    ],
  },
  {
    label: 'UTV',
    color: '#9F9177',
    href: '/shop/utv',
    image: `${prefix}/assets/utv_1920x2560.png`,
    sections: [
      {
        label: 'Accessories',
        href: '/shop/utv-accessories',
        links: [
          {
            label: 'Fluids & Lubrication',
            href: '/shop/utv-accessories/fluids-lubrication',
          },
          {
            label: 'Ramps & Stands',
            href: '/shop/utv-accessories/ramps-stands',
          },
          {
            label: 'Tie Downs & Straps',
            href: '/shop/utv-accessories/tie-downs-straps',
          },
          {
            label: 'Tools & Maintenance',
            href: '/shop/utv-accessories/tools-maintenance',
          },
        ],
      },
      {
        label: 'Riding Gear',
        href: '/shop/utv-riding-gear',
        links: [
          {
            label: 'Boots',
            href: '/shop/riding-gear/utv-boots',
          },
          {
            label: 'Gear',
            href: '/shop/riding-gear/utv-gear',
          },
          {
            label: 'Goggles & Accessories',
            href: '/shop/riding-gear/utv-goggles-accessories',
          },
          {
            label: 'Helmets',
            href: '/shop/riding-gear/utv-helmets',
          },
          {
            label: 'Protective',
            href: '/shop/riding-gear/utv-protective',
          },
        ],
      },
      {
        label: 'UTV Parts',
        href: '/shop/utv-parts',
        links: [
          {
            label: 'Exterior',
            href: '/shop/utv-parts/exterior',
          },
          {
            label: 'Hunting Accessories',
            href: '/shop/utv-parts/hunting-accessories',
          },
          {
            label: 'Interior',
            href: '/shop/utv-parts/interior',
          },
          {
            label: 'Lift Kits',
            href: '/shop/utv-parts/lift-kits',
          },
          {
            label: 'Performance',
            href: '/shop/utv-parts/performance',
          },
          {
            label: 'Protection',
            href: '/shop/utv-parts/protection',
          },
          {
            label: 'Tires & Wheels',
            href: '/shop/utv-parts/tires-wheels',
          },
          {
            label: 'Winches & Snow Plow Kits',
            href: '/shop/utv-parts/winches-snow-plow-kits',
          },
        ],
      },
      // {
      //   label: 'OEM Parts',
      //   href: '/shop/oem-utv-parts',
      //   //     'ARCTIC CAT', 'CAN-AM', 'HONDA', 'JOHN DEERE', 'KAWASAKI', 'KUBOTA', 'KYMCO', 'POLARIS',
      //   // 'SUZUKI', 'TEXTRON', 'TRACKER', 'YAMAHA'
      //   links: [
      //     {
      //       label: 'Arctic Cat',
      //       href: '/shop/oem-arctic-cat-utv-parts',
      //     },
      //     {
      //       label: 'Can-Am',
      //       href: '/shop/oem-can-am-utv-parts',
      //     },
      //     {
      //       label: 'Honda',
      //       href: '/shop/oem-honda-utv-parts',
      //     },
      //     {
      //       label: 'Kawasaki',
      //       href: '/shop/oem-kawasaki-utv-parts',
      //     },

      //     {
      //       label: 'Polaris',
      //       href: '/shop/oem-polaris-utv-parts',
      //     },
      //     {
      //       label: 'Suzuki',
      //       href: '/shop/oem-suzuki-utv-parts',
      //     },
      //     {
      //       label: 'Yamaha',
      //       href: '/shop/oem-yamaha-utv-parts',
      //     },
      //   ],
      // },
    ],
  },
  {
    label: 'Street',
    color: '#852C2C',
    href: '/shop/motorcycle',
    image: `${prefix}/assets/street_1920x2560.png`,
    sections: [
      {
        label: 'Motorcycle Riding Gear',
        href: '/shop/motorcycle-riding-gear',
        links: [
          {
            label: 'Helmets',
            href: '/shop/motorcycle-helmets',
          },
          {
            label: 'Protection',
            href: '/shop/motorcycle-protection',
          },
          {
            label: 'Goggles & Accessories',
            href: '/shop/motorcycle-goggles-accessories',
          },
          {
            label: 'Boots',
            href: '/shop/motorcycle-boots',
          },
          {
            label: 'Gear',
            href: '/shop/motorcycle-gear',
          },
        ],
      },
      {
        label: 'Motorcycle Parts',
        href: '/shop/motorcycle-parts',
        links: [
          {
            label: 'Bars & Controls',
            href: '/shop/motorcycle-parts/bars-controls',
          },
          {
            label: 'Body Parts & Accessories',
            href: '/shop/motorcycle-parts/body-parts-accessories',
          },
          {
            label: 'Brakes',
            href: '/shop/motorcycle-parts/brakes',
          },
          {
            label: 'Cooling Systems',
            href: '/shop/motorcycle-parts/cooling-systems',
          },
          {
            label: 'Drive',
            href: '/shop/motorcycle-parts/drive',
          },
          {
            label: 'Engine',
            href: '/shop/motorcycle-parts/engine',
          },
          {
            label: 'Exhaust',
            href: '/shop/motorcycle-parts/exhaust',
          },
          {
            label: 'Fuel System',
            href: '/shop/motorcycle-parts/fuel-system',
          },
          {
            label: 'Intake',
            href: '/shop/motorcycle-parts/intake',
          },
          {
            label: 'Lights & Electrical',
            href: '/shop/motorcycle-parts/lights-electrical',
          },
          {
            label: 'Suspension',
            href: '/shop/motorcycle-parts/suspension',
          },
          {
            label: 'Wheels',
            href: '/shop/motorcycle-parts/wheels',
          },
        ],
      },
      {
        label: 'Shop By Make',
        href: '/shop/oem-motorcycle-parts',
        links: [
          {
            label: 'Kawasaki',
            href: '/shop/kawasaki-motorcycle-parts',
          },
          {
            label: 'Can-Am',
            href: '/shop/can-am-motorcycle-parts',
          },
          {
            label: 'Triumph',
            href: '/shop/triumph-motorcycle-parts',
          },

          {
            label: 'Harley-Davidson',
            href: '/shop/harley-davidson-motorcycle-parts',
          },
          {
            label: 'Honda',
            href: '/shop/honda-motorcycle-parts',
          },
          {
            label: 'KTM',
            href: '/shop/ktm-motorcycle-parts',
          },
          {
            label: 'Polaris',
            href: '/shop/polaris-motorcycle-parts',
          },
          {
            label: 'Suzuki',
            href: '/shop/suzuki-motorcycle-parts',
          },
          {
            label: 'Yamaha',
            href: '/shop/yamaha-motorcycle-parts',
          },
        ],
      },
    ],
  },
  {
    label: 'Cycling',
    color: '#4A6E4B',
    href: '/shop/cycling',
    image: `${prefix}/assets/cycling_1920x2560.png`,
    sections: [
      {
        label: 'E-Bike',
        href: '/shop/cycling/e-bikes',
        links: [
          {
            label: 'Accessories',
            href: '/shop/cycling/e-bike-accessories',
          },
          {
            label: 'E-Bike Apparel',
            href: '/shop/cycling/e-bike-apparel',
          },
          {
            label: 'E-Bike Parts',
            href: '/shop/cycling/e-bike-parts',
          },
          {
            label: 'E-Bikes',
            href: '/shop/cycling/e-bikes/bikes',
          },
        ],
      },
      {
        label: 'Mountain Bikes',
        href: '/shop/cycling/mountain-bikes',
        links: [
          {
            label: 'Accessories',
            href: '/shop/cycling/mountain-bike-accessories',
          },
          {
            label: 'Mountain Bike Apparel',
            href: '/shop/cycling/mountain-bike-apparel',
          },
          {
            label: 'Mountain Bike Parts',
            href: '/shop/cycling/mountain-bike-parts',
          },
          {
            label: 'Mountain Bikes',
            href: '/shop/cycling/mountain-bikes/bikes',
          },
        ],
      },
      {
        label: 'Road Bikes',
        href: '/shop/cycling/road-bikes',
        links: [
          {
            label: 'Accessories',
            href: '/shop/cycling/road-bike-accessories',
          },
          {
            label: 'Road Bike Apparel',
            href: '/shop/cycling/road-bike-apparel',
          },
          {
            label: 'Road Bike Parts',
            href: '/shop/cycling/road-bike-parts',
          },
          {
            label: 'Road Bikes',
            href: '/shop/cycling/road-bikes/bikes',
          },
        ],
      },
    ],
  },
  {
    label: 'Snow',
    image: `${prefix}/assets/snow_1920x2560.png`,
    color: '#BCCBDD',
    href: '/shop/snowmobiles',
    sections: [
      {
        label: 'Accessories',
        href: '/shop/snowmobile-accessories',
        links: [
          {
            label: 'Communication Systems',
            href: '/shop/snowmobile-accessories/communication-systems',
          },
          {
            label: 'Fluids & Lubrication',
            href: '/shop/snowmobile-accessories/fluids-lubrication',
          },
          {
            label: 'Gear Bags',
            href: '/shop/snowmobile-accessories/gear-bags',
          },
          {
            label: 'Owner & Service Manuals',
            href: '/shop/snowmobile-accessories/owner-service-manuals',
          },
          {
            label: 'Safety & Survival',
            href: '/shop/snowmobile-accessories/safety-survival',
          },
          {
            label: 'Tie Downs & Straps',
            href: '/shop/snowmobile-accessories/tie-downs-straps',
          },
          {
            label: 'Tools & Maintenance',
            href: '/shop/snowmobile-accessories/tools-maintenance',
          },
        ],
      },
      {
        label: 'Riding Gear',
        href: '/shop/snowmobile-riding-gear',
        links: [
          {
            label: 'Boots',
            href: '/shop/riding-gear/snowmobile-boots',
          },
          {
            label: 'Facemasks & Balaclavas',
            href: '/shop/riding-gear/snowmobile-facemasks-balaclavas',
          },
          {
            label: 'Gear',
            href: '/shop/snowmobile-gear',
          },
          {
            label: 'Goggles & Accessories',
            href: '/shop/riding-gear/snowmobile-goggles-accessories',
          },
          {
            label: 'Helmets',
            href: '/shop/riding-gear/snowmobile-helmets',
          },
          {
            label: 'Protective',
            href: '/shop/riding-gear/snowmobile-protective',
          },
        ],
      },
      {
        label: 'Snowmobile Parts',
        href: '/shop/snowmobile-parts',
        links: [
          {
            label: 'Body Parts & Accessories',
            href: '/shop/snowmobile-parts/body-parts-accessories',
          },
          {
            label: 'Electrical & Lighting',
            href: '/shop/snowmobile-parts/electrical-lighting',
          },
          {
            label: 'Engine & Intake',
            href: '/shop/snowmobile-parts/engine-intake',
          },
          {
            label: 'Exhaust',
            href: '/shop/snowmobile-parts/exhaust',
          },
          {
            label: 'Skis & Belts',
            href: '/shop/snowmobile-parts/skis-belts',
          },
          {
            label: 'Suspension',
            href: '/shop/snowmobile-parts/suspension',
          },
        ],
      },
    ],
    excludeFromNav: true,
  },
  {
    label: 'Casual',
    color: '#8C5D77',
    href: '/shop/casual',
    image: `${prefix}/assets/casual-png_1920x2560.png `,
    sections: [
      {
        label: 'Shop All Casual',
        href: '/shop/casual',
        links: [
          {
            label: 'Hats & Beanies',
            href: '/shop/casual/hats-beanies',
          },
          {
            label: 'Jackets & Sweaters',
            href: '/shop/casual/jackets-sweaters',
          },
          {
            label: 'Shirts',
            href: '/shop/casual/shirts',
          },
          {
            label: 'Shoes',
            href: '/shop/casual/shoes',
          },
          {
            label: 'Socks',
            href: '/shop/casual/socks',
          },
          {
            label: 'Sunglasses',
            href: '/shop/casual/sunglasses',
          },
        ],
      },
    ],
  },
  {
    label: 'Collectibles',
    color: '#01B7B3',
    href: '/shop/collectibles',
    image: `${prefix}/assets/collectibles_1920x2560.png`,
    sections: [
      {
        label: 'Collectibles',
        href: '/shop/collectibles',
      },
    ],
  },
];

export default categories;
