'use client';

import ProductCardSkeleton from '@c/skeletons/ProductCardSkeleton';
import useIntersectionObserver from '@util/hooks/useIntersectionObserver';
import { useEffect, useRef, useState } from 'react';
import PaddockPostCard from './components/PaddockPost';
import { BlogDocument } from '@util/types/firestore/blog';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { ChevronRightIcon } from '@c/icons';
import PageSectionHeader from '@ui/PageSectionHeader';

export default function ThePaddock({ posts }: { posts: BlogDocument[] }) {
  const [loading, setLoading] = useState(true);

  const INCREMENT_BY = 5;
  const FLEX_GAP = 16;
  const [currentIndex, setCurrent] = useState(0);

  const carouselfRef = useRef<HTMLDivElement>(null);
  const frontRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const frontEntry = useIntersectionObserver(frontRef, {});
  const endEntry = useIntersectionObserver(endRef, {});

  const handleLeftArrowClick = () => {
    setCurrent((prev) => Math.max(prev - INCREMENT_BY, 0));
  };

  const handleRightArrowClick = () =>
    setCurrent((prev) =>
      Math.min(prev + INCREMENT_BY, posts ? posts.length : 0)
    );

  useEffect(() => {
    if (carouselfRef.current && cardRef.current) {
      const cardWidth = currentIndex * cardRef.current.offsetWidth;
      const cardSpacing = currentIndex * FLEX_GAP;
      const offset = cardWidth + cardSpacing;
      carouselfRef.current.scrollLeft = offset;
    }
  }, [currentIndex]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  });

  return (
    <div className="w-full">
      <div className="mt-4">
        <div className="relative w-full">
          {posts && posts.length > 0 ? (
            <>
              {/* left arrow */}
              {!frontEntry?.isIntersecting && (
                <button
                  className="absolute -left-16 top-0 z-20 hidden h-full items-center sm:flex"
                  onClick={() => handleLeftArrowClick()}
                >
                  <ChevronLeftIcon className="h-14 w-14 cursor-pointer text-brand-secondary" />
                </button>
              )}

              <div
                className="no-scrollbar w-full overflow-x-scroll scroll-smooth px-4 pb-[1rem] sm:px-0 lg:overflow-x-hidden"
                ref={carouselfRef}
              >
                <div className="mobile-zoom-product-card-container flex w-min gap-x-[3rem] gap-y-[1.6rem] p-4 ">
                  {posts?.map((post, i) => {
                    // append extra div to front of first item for frontRef
                    if (i === 0) {
                      return (
                        <div key={i} className="flex">
                          <div ref={frontRef} />
                          <div ref={i === 0 ? cardRef : null}>
                            <PaddockPostCard post={post} />
                          </div>
                        </div>
                      );
                    }
                    // append extra div to end of last item for endRef
                    if (i === posts.length - 1) {
                      return (
                        <div key={i} className="flex">
                          <div ref={i === 0 ? cardRef : null}>
                            <PaddockPostCard post={post} />
                          </div>
                          <div ref={endRef} />
                        </div>
                      );
                    }
                    return (
                      <div key={i}>
                        <PaddockPostCard post={post} />
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* right arrow */}
              {!endEntry?.isIntersecting && (
                <button
                  className="absolute -right-16 top-0 z-10 hidden h-full items-center sm:flex"
                  onClick={() => handleRightArrowClick()}
                >
                  <ChevronRightIcon className="h-14 w-14 cursor-pointer text-brand-secondary" />
                </button>
              )}
            </>
          ) : loading ? (
            <div className="flex gap-[1.6rem]">
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
              <ProductCardSkeleton />
            </div>
          ) : (
            <div className="flex gap-[1.6rem]">
              <h3>No posts found</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
