import Carousel from '@ui/Carousel';
import { getItems, logEvent } from '@util/analytics';
import useLogWhenInView from '@util/hooks/useLogWhenInView';
import { useRecentlyViewed } from '../hooks';
import { renderProductCards } from '../utils';
import { useAuth } from 'context/AuthContext';
import Button from '@ui/Button';

const RecentlyViewed = () => {
  const { recentlyViewedProducts } = useRecentlyViewed();
  const { userDoc } = useAuth();

  useLogWhenInView(() => {
    if (recentlyViewedProducts && recentlyViewedProducts.length > 4) {
      logEvent('view_item_list', {
        item_list_name: 'Recently Viewed',
        item_list_id: 'recently_viewed',
        items: getItems(recentlyViewedProducts),
      });
    }
  });

  if (!userDoc) {
    return (
      <div className="flex h-[30rem] w-full items-center justify-center">
        <div className="flex flex-col items-center">
          <h1 className="text-[2rem] font-semibold">
            You&apos;re not signed in
          </h1>
          <p className="text-[1.6rem]">
            Please sign in to view your recently viewed items
          </p>

          <Button
            text="Sign In"
            href="/login"
            className="mt-4"
            type="secondary"
            radius={'xl'}
          />
        </div>
      </div>
    );
  } else {
    return recentlyViewedProducts && recentlyViewedProducts.length > 4 ? (
      <Carousel
        items={renderProductCards(
          recentlyViewedProducts,
          {
            list_name: 'recently_viewed',
            list_id: 'recently_viewed',
          },
          {
            data_source: 'typesense',
            page: 'homepage',
            section: 'recently_viewed',
          }
        )}
      />
    ) : null;
  }
};

export default RecentlyViewed;
